var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('TableLayout',{ref:"TableLayout",attrs:{"columns":_vm.dialogOptions.dialog.title === '现金督查记录'
        ? _vm.getColumns.filter(
            function (v) { return v.value !== 'startTime' && v.value !== 'endTime'; }
          )
        : _vm.getColumns,"query-format":_vm.queryFormat,"format-global-query":_vm.formatGlobalQuery,"url":("/" + _vm.$businessISG + "/monitor/" + _vm.prefix + "/" + _vm.prefix + "-page")},scopedSlots:_vm._u([{key:"query-form",fn:function(ref){
        var queryParam = ref.queryParam;
return [_c('QueryLayout',{attrs:{"lable":"机构名称"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          },"placeholder":"请选择机构名称"},model:{value:(queryParam.organizationUUID),callback:function ($$v) {_vm.$set(queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1),_c('QueryLayout',{attrs:{"lable":"检测类型"}},[_c('Select',{attrs:{"options":_vm.cashAnalysisItemList,"replace-fields":{
            lable: 'itemName',
            value: 'uuid',
          },"placeholder":"请选择检测类型"},model:{value:(queryParam.searchAnalysisItems),callback:function ($$v) {_vm.$set(queryParam, "searchAnalysisItems", $$v)},expression:"queryParam.searchAnalysisItems"}})],1),(_vm.isViolation)?[_c('QueryLayout',{attrs:{"lable":"审核状态"}},[_c('Select',{attrs:{"options":_vm.reviewStatus,"replace-fields":{
              lable: 'msg',
              value: 'code',
            },"placeholder":"请选择审核状态"},model:{value:(queryParam.reviewStatus),callback:function ($$v) {_vm.$set(queryParam, "reviewStatus", $$v)},expression:"queryParam.reviewStatus"}})],1),_c('QueryLayout',{attrs:{"lable":"违规时间"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.time),callback:function ($$v) {_vm.$set(queryParam, "time", $$v)},expression:"queryParam.time"}})],1)]:[_c('QueryLayout',{attrs:{"lable":"检测结果"}},[_c('Select',{attrs:{"options":_vm.violationFlag,"replace-fields":{
              lable: 'msg',
              value: 'code',
            },"placeholder":"请选择检测结果"},model:{value:(queryParam.violationFlag),callback:function ($$v) {_vm.$set(queryParam, "violationFlag", $$v)},expression:"queryParam.violationFlag"}})],1),_c('QueryLayout',{attrs:{"lable":"开始时间"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.time),callback:function ($$v) {_vm.$set(queryParam, "time", $$v)},expression:"queryParam.time"}})],1)]]}},{key:"table-item-action",fn:function(ref){
            var row = ref.row;
return _c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$refs.EarlyWarningHandleInfoDialog.openDialog({ row: row })}}},[_c('div',{staticStyle:{"color":"#25c4ff"}},[_vm._v("查看")])])}}],null,false,344013072)}),_c('EarlyWarningHandleInfoDialog',{ref:"EarlyWarningHandleInfoDialog",attrs:{"prefix":_vm.prefix},on:{"success":function($event){_vm.$refs.TableLayout && _vm.$refs.TableLayout.getData()}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }