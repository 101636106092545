<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      ref="TableLayout"
      :columns="
        dialogOptions.dialog.title === '现金督查记录'
          ? getColumns.filter(
              (v) => v.value !== 'startTime' && v.value !== 'endTime'
            )
          : getColumns
      "
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/${prefix}/${prefix}-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>
        <QueryLayout lable="检测类型">
          <Select
            v-model="queryParam.searchAnalysisItems"
            :options="cashAnalysisItemList"
            :replace-fields="{
              lable: 'itemName',
              value: 'uuid',
            }"
            placeholder="请选择检测类型"
          ></Select>
        </QueryLayout>
        <template v-if="isViolation">
          <QueryLayout lable="审核状态">
            <Select
              v-model="queryParam.reviewStatus"
              :options="reviewStatus"
              :replace-fields="{
                lable: 'msg',
                value: 'code',
              }"
              placeholder="请选择审核状态"
            ></Select>
          </QueryLayout>
          <QueryLayout lable="违规时间">
            <DatePicker v-model="queryParam.time" range></DatePicker>
          </QueryLayout>
        </template>
        <template v-else>
          <QueryLayout lable="检测结果">
            <Select
              v-model="queryParam.violationFlag"
              :options="violationFlag"
              :replace-fields="{
                lable: 'msg',
                value: 'code',
              }"
              placeholder="请选择检测结果"
            ></Select>
          </QueryLayout>
          <QueryLayout lable="开始时间">
            <DatePicker v-model="queryParam.time" range></DatePicker>
          </QueryLayout>
        </template>
      </template>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
        @click="$refs.EarlyWarningHandleInfoDialog.openDialog({ row })"
      >
        <div style="color: #25c4ff">查看</div>
      </div>
    </TableLayout>
    <EarlyWarningHandleInfoDialog
      ref="EarlyWarningHandleInfoDialog"
      :prefix="prefix"
      @success="$refs.TableLayout && $refs.TableLayout.getData()"
    ></EarlyWarningHandleInfoDialog>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import Select from '@/views/screen/components/Select.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import EarlyWarningHandleInfoDialog from '@/views/screen/earlyWarning/modules/EarlyWarningHandleInfoDialog.vue';
export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    Select,
    SelectTree,
    DatePicker,
    EarlyWarningHandleInfoDialog,
  },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'cash',
    },
  },
  data() {
    return {
      executionMap: {
        待执行: 'rgb(191, 191, 191)',
        执行中: 'rgb(25, 144, 255)',
        完成: 'rgb(103, 194, 58)',
        超时: 'rgb(251, 16, 16)',
      },
      arr: [
        { lable: '卡把', value: 1 },
        { lable: '加钞', value: 2 },
      ],
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '任务列表',
        },
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      organizationTree: (state) => state.screenStore.organizationTree,
      cashAnalysisItemList: (state) =>
        state.screenStore.analysisitem['cashAnalysisItemList']
          ? state.screenStore.analysisitem['cashAnalysisItemList']
          : [],
      reviewStatus: (state) =>
        state.screenStore.commondict['MonitorDataReviewStatusEnum']
          ? state.screenStore.commondict['MonitorDataReviewStatusEnum']
          : [],
      violationFlag: (state) =>
        state.screenStore.commondict['MonitorDataViolationFlagEnum']
          ? state.screenStore.commondict['MonitorDataViolationFlagEnum']
          : [],
    }),
    isViolation() {
      return (
        this.dialogOptions.query && this.dialogOptions.query.status == '02'
      );
    },
    getColumns() {
      return [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
          type: ['reviewStatus', 'violationFlag'],
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
          type: ['reviewStatus', 'violationFlag'],
        },
        {
          text: '违规时间',
          value: 'warningTime',
          sortable: false,
          align: 'center',
          type: ['violationFlag'],
        },
        {
          text: '操作位置',
          value: 'cameraName',
          sortable: false,
          align: 'center',
          type: ['reviewStatus', 'violationFlag'],
        },
        {
          text: '检测类型',
          value: 'analysisItemText',
          sortable: false,
          align: 'center',
          type: ['reviewStatus', 'violationFlag'],
        },
        {
          text: '违规内容',
          value: 'description',
          sortable: false,
          align: 'center',
          type: ['violationFlag'],
        },
        {
          text: '审核人',
          value: 'reviewName',
          sortable: false,
          align: 'center',
          type: ['violationFlag'],
        },
        {
          text: '开始时间',
          value: 'startTime',
          sortable: false,
          align: 'center',
          type: [],
        },
        {
          text: '结束时间',
          value: 'endTime',
          sortable: false,
          align: 'center',
          type: [],
        },
        {
          text: '预警时间',
          value: 'warningTime',
          sortable: false,
          align: 'center',
          type: ['reviewStatus'],
        },
        {
          text: '检测结果',
          value: 'violationFlagText',
          sortable: false,
          align: 'center',
          type: ['reviewStatus'],
        },
        {
          text: '不合规理由',
          value: 'description',
          sortable: false,
          align: 'center',
          type: ['reviewStatus'],
        },
        {
          text: '审核状态',
          value: 'reviewStatusText',
          sortable: false,
          align: 'center',
          type: ['reviewStatus', 'violationFlag'],
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
          type: ['reviewStatus', 'violationFlag'],
        },
      ].filter((e) =>
        e.type.includes(this.isViolation ? 'violationFlag' : 'reviewStatus')
      );
    },
  },
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.dialogOptions = {
          dialog: {
            width: 1500,
            title: '任务列表',
          },
        };
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        ...this.dialogOptions.fixedParam,
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      if (this.dialogOptions.query)
        query = Object.assign(query, this.dialogOptions.query);
      return query;
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
