<template>
  <div style="height: 100%">
    <div class="cashSupervision">
      <div class="cashSupervision_left">
        <EarlyWarningTotalLeft
          :key="refreshKey"
          :global-query="globalQuery"
          style="margin-bottom: 29px"
        ></EarlyWarningTotalLeft>
        <EarlyWarningHistoryLeft
          :refresh-key="refreshKey"
        ></EarlyWarningHistoryLeft>
      </div>
      <div class="cashSupervision_center">
        <div class="img_center">
          <FloatBox>
            <template slot="one-line">
              <template v-for="code of [4,5,6]">
                <template v-if="list[code]">
                  <div
                    v-for="(e, i) in [list[code]]"
                    :key="i + code"
                    class="line-box"
                  >
                    <span>{{ e.text }}：</span>
                    <span
                      class="cyan-value"
                      style="cursor: pointer"
                      @click="
                        $bus.$emit('ViolationTableDialog', {
                          dialog: { title: '现金督查记录' },
                          fixedParam: {
                            searchAnalysisItems: e.id,
                          },
                        })
                      "
                    >{{ formatString(e.totalCount) }}</span
                    >
                    <span class="separate-value">/</span>
                    <span
                      class="red-value"
                      style="cursor: pointer"
                      @click="
                        $bus.$emit('ViolationTableDialog', {
                          dialog: { title: '违规预警记录' },
                          query: {
                            violationFlag: '02',
                          },
                          fixedParam: {
                            searchAnalysisItems: e.id,
                          },
                        })
                      "
                    >{{ formatString(e.count) }}</span
                    >
                  </div>
                </template>
              </template>
            </template>
            <template slot="two-line">
              <template v-for="code of [2, 3]">
                <template v-if="list[code]">
                  <div
                    v-for="(e, i) in [list[code]]"
                    :key="i + code"
                    class="line-box"
                  >
                    <span>{{ e.text }}：</span>
                    <span
                      class="cyan-value"
                      style="cursor: pointer"
                      @click="
                        $bus.$emit('ViolationTableDialog', {
                          dialog: { title: '现金督查记录' },
                          fixedParam: {
                            searchAnalysisItems: e.id,
                          },
                        })
                      "
                    >{{ formatString(e.totalCount) }}</span
                    >
                    <span class="separate-value">/</span>
                    <span
                      class="red-value"
                      style="cursor: pointer"
                      @click="
                        $bus.$emit('ViolationTableDialog', {
                          dialog: { title: '违规预警记录' },
                          query: {
                            violationFlag: '02',
                          },
                          fixedParam: {
                            searchAnalysisItems: e.id,
                          },
                        })
                      "
                    >{{ formatString(e.count) }}</span
                    >
                  </div>
                </template>
              </template>
            </template>
            <template slot="three-line">
              <template v-for="code of [0,1]">
                <template v-if="list[code]">
                  <div
                    v-for="(e, i) in [list[code]]"
                    :key="i + code"
                    class="line-box"
                  >
                    <span>{{ e.text }}：</span>
                    <span
                      class="cyan-value"
                      style="cursor: pointer"
                      @click="
                        $bus.$emit('ViolationTableDialog', {
                          dialog: { title: '现金督查记录' },
                          fixedParam: {
                            searchAnalysisItems: e.id,
                          },
                        })
                      "
                    >{{ formatString(e.totalCount) }}</span
                    >
                    <span class="separate-value">/</span>
                    <span
                      class="red-value"
                      style="cursor: pointer"
                      @click="
                        $bus.$emit('ViolationTableDialog', {
                          dialog: { title: '违规预警记录' },
                          query: {
                            violationFlag: '02',
                          },
                          fixedParam: {
                            searchAnalysisItems: e.id,
                          },
                        })
                      "
                    >{{ formatString(e.count) }}</span
                    >
                  </div>
                </template>
              </template>
            </template>
          </FloatBox>
        </div>
        <EarlyWarningTrendFooter
          :global-query="globalQuery"
          :refresh-key="refreshKey"
        ></EarlyWarningTrendFooter>
      </div>
      <div class="cashSupervision_right">
        <EarlyWarningHandleRight
          :global-query="globalQuery"
          :refresh-key="refreshKey"
          type='cashSupervision'
        ></EarlyWarningHandleRight>
      </div>
    </div>
    <ViolationTableDialog ref="ViolationTableDialog"></ViolationTableDialog>
    <EarlyWarningTableDialog
      ref="EarlyWarningTableDialog"
      prefix="cash"
      :analysis-item-list="cashAnalysisItemList"
    ></EarlyWarningTableDialog>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {formatString} from '@/views/screen/config';
import ViolationTableDialog from './modules/ViolationTableDialog.vue';
import EarlyWarningTableDialog from '@/views/screen/earlyWarning/modules/EarlyWarningTableDialog.vue';

export default {
  //组件注册
  components: {
    EarlyWarningTotalLeft: () => import('./modules/EarlyWarningTotalLeft.vue'),
    EarlyWarningHistoryLeft: () =>
      import('./modules/EarlyWarningHistoryLeft.vue'),
    FloatBox: () => import('./components/FloatBox.vue'),
    EarlyWarningTrendFooter: () =>
      import('./modules/EarlyWarningTrendFooter.vue'),
    EarlyWarningHandleRight: () =>
      import('./modules/EarlyWarningHandleRight.vue'),
    ViolationTableDialog,
    EarlyWarningTableDialog,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      num: 0,
      list: [],
      timer: null,
    };
  },
  //计算属性
  computed: {
    ...mapState({
      globalQuery: (state) => state.screenStore.globalQuery,
      warningTotal: (state) => state.screenStore.warningTotal,
      cashAnalysisItemList: (state) =>
        state.screenStore.analysisitem['cashAnalysisItemList']
          ? state.screenStore.analysisitem['cashAnalysisItemList']
          : [],
    }),
    getWarningTotal() {
      return (code) =>
        this.warningTotal.down &&
        this.warningTotal.down.find((e) => e.code == code);
    },
  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      handler: 'getMonitordata',
    },
    refreshKey: {
      handler: 'getMonitordata',
    },
  },
  //DOM访问
  mounted() {
    this.$bus.$on('ViolationTableDialog', (options) => {
      this.$refs.ViolationTableDialog &&
      this.$refs.ViolationTableDialog.openDialog(options);
    });
    this.$bus.$on('EarlyWarningTableDialog', (options) => {
      this.$refs.EarlyWarningTableDialog &&
      this.$refs.EarlyWarningTableDialog.openDialog(options);
    });
    this.$bus.$on('warning-success-cash', () => this.getMonitordata);
    if (Object.keys(this.globalQuery).length) {
      this.getMonitordata();
    }
  },
  //保存方法
  methods: {
    sliceList(down) {
      let length = down.length / 5;
      let arr = [];
      length = Math.floor(length);
      length = down.length % 5 === 0 ? length : length + 1;
      for (let i = 0; i < length; i++) {
        arr.push(down.slice(5 * i, 5 * i + 5));
      }
      return arr;
    },
    formatString,
    ...mapMutations(['screenStore/SET_warningTotal']),
    async getMonitordata() {
      // clearInterval(this.timer)
      this.list = []
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/cash/violation-stat`,
        this.globalQuery
      );
      if (data.code == 200 && data.data) {
        const list = data.data[0].down;
        this.list = list;
        this['screenStore/SET_warningTotal'](data.data[0]);
        // this.timer = setInterval(() => {
        //   this.num = this.num === list.length - 1 ? 0 : this.num + 1;
        //   this.list = list[this.num];
        // }, 10000);
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.cashSupervision {
  display: flex;
  height: 100%;

  .cashSupervision_left,
  .cashSupervision_right {
    flex: 0 0 26%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .layoutBox {
      flex: 1;
      overflow: hidden;
    }
  }

  .cashSupervision_center {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 38px;
    overflow: hidden;

    .img_center {
      flex: 1;
      overflow: hidden;
      background-image: url('../../../assets/screen/bj/cashSupervision_center.gif');
      background-position: bottom;
    }

    .layoutBox {
      flex: 0 0 245px;
      overflow: hidden;
    }
  }
}

::v-deep .float-box {
  .one, .two {
    .line-box:nth-child(2)  {
      margin-left: 30px;
    }
  }
}
</style>
